import React, { useState, useEffect } from "react";
import Web3 from "web3";
import ReactPlayer from "react-player";
import { mintNFT, getBalanceOf, getTotalSupply } from "./stateFunctions";
import NFTDetails from "./NFTDetails";

const MetaMaskConnection = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [error, setError] = useState(null);
  const [mintToAddress, setMintToAddress] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [balance, setBalance] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [queryAddress, setQueryAddress] = useState("");
  const [lastMintedUri, setLastMintedUri] = useState("");
  const [tokenURIContent, setTokenURIContent] = useState(null);

  const contractAddress = "0x5a44cb58172ee05940ee07a238fff86852734ce5";

  const contractABI = [
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "string",
          name: "uri",
          type: "string",
        },
      ],
      name: "safeMint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "tokenURI",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "getApproved",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "unpause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  const requiredNetwork = {
    name: "Base Mainnet",
    chainId: "8453",
    rpc: "https://mainnet.base.org",
    explorer: "https://explorer.base.org",
  };

  const contractExplorerLink = `https://basescan.org/address/${contractAddress}`;

  useEffect(() => {
    const checkNetwork = async () => {
      try {
        if (window.ethereum) {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          setAccount(accounts[0]);

          const networkId = await web3Instance.eth.net.getId();
          if (networkId.toString() !== requiredNetwork.chainId) {
            setError(`Please connect MetaMask to ${requiredNetwork.name}`);
          } else {
            setError(null);
            const contractInstance = new web3Instance.eth.Contract(
              contractABI,
              contractAddress
            );
            setContract(contractInstance);
          }
        } else {
          setError("MetaMask not detected");
        }
      } catch (err) {
        setError("Error connecting to MetaMask");
      }
    };

    checkNetwork();
  }, []);

  useEffect(() => {
    if (tokenURI) {
      fetchTokenURIContent(tokenURI);
    }
  }, [tokenURI]);

  const handleMint = async () => {
    if (contract && account) {
      try {
        await mintNFT(
          contract,
          account,
          mintToAddress,
          tokenURI,
          web3.utils.toWei("0.0002", "ether")
        );
        alert("Minting successful");
      } catch (err) {
        setError("Minting failed");
        console.error(err);
      }
    } else {
      setError("Contract not connected or account not set");
    }
  };

  const fetchTokenURIContent = async (uri) => {
    try {
      const response = await fetch(uri);
      const data = await response.json();
      setTokenURIContent(data);
    } catch (error) {
      setError(`Error fetching Token URI content: ${error.message}`);
    }
  };

  const handleGetBalance = async () => {
    if (contract) {
      try {
        const balance = await getBalanceOf(contract, queryAddress);
        setBalance(balance);
      } catch (err) {
        setError("Error fetching balance");
        console.error(err);
      }
    } else {
      setError("Contract not connected");
    }
  };

  return (
    <div className="container">
      <header className="header">
        <h1>Register Your Claim Here</h1>
      </header>

      <div>
        <div
          style={{
            maxWidth: "800px",
            margin: "auto",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <header
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "10px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            <h1 style={{ fontSize: "24px", margin: "0" }}>
              Public Web3 Blockchain Connection
            </h1>
          </header>

          <div
  style={{
    backgroundColor: "#f0f0f0",
    padding: "20px",
    borderRadius: "5px",
    marginBottom: "20px",
  }}
>
  {error ? (
    <p>{error}</p>
  ) : account ? (
    <div style={{ overflowX: "auto" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ width: "40%", paddingBottom: "10px" }}>
              Connected as:
            </td>
            <td style={{ paddingBottom: "10px" }}>{account}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "10px" }}>Network Name:</td>
            <td style={{ paddingBottom: "10px" }}>{requiredNetwork.name}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "10px" }}>Chain ID:</td>
            <td style={{ paddingBottom: "10px" }}>{requiredNetwork.chainId}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "10px" }}>Blockchain RPC:</td>
            <td style={{ paddingBottom: "10px" }}>{requiredNetwork.rpc}
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "10px" }}>Base Explorer:</td>
            <td style={{ paddingBottom: "10px" }}>
              <a
                href={requiredNetwork.explorer}
                target="_blank"
                rel="noopener noreferrer"
              >
                {requiredNetwork.explorer}
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "10px" }}>Smart-Contract Address:</td>
            <td style={{ paddingBottom: "10px" }}>
              <a
                href={contractExplorerLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contractAddress}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <p>Connecting to Account Manager...</p>
  )}
</div>

        </div>
      </div>

      <div
        style={{
          fontFamily: "Arial, sans-serif",
          padding: "20px",
          maxWidth: "90%",
          margin: "0 auto",
        }}
      >
        <h1>Evidencer Digital Artifact</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <p>Connected account: {account}</p>
        <input
          type="text"
          placeholder="Attach a claim to this address: Enter 0x Address here"
          value={mintToAddress}
          onChange={(e) => setMintToAddress(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            fontSize: "14px",
            boxSizing: "border-box",
          }}
        />
        <input
          type="text"
          placeholder="Set the Evidentiary URI in JSON Format. Enter link to URI here."
          value={tokenURI}
          onChange={(e) => setTokenURI(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            fontSize: "14px",
            boxSizing: "border-box",
          }}
        />

        <div className="link-container">
          <a
            href="https://encrypted-ipfs-upload.freeweb3.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Instructions on Making an Encrypted Evidentiary JSON File
          </a>
        </div>
        <br />
        {tokenURIContent && (
          <div
            style={{
              padding: "20px",
              border: "1px solid #ccc",
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {tokenURIContent.image && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  src={tokenURIContent.image}
                  alt="Token"
                  style={{ width: "80%" }}
                />
              </div>
            )}
            {tokenURIContent.animation_url && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <ReactPlayer
                  url={tokenURIContent.animation_url}
                  controls
                  playing
                  loop
                  width="80%"
                />
              </div>
            )}

            <div style={{ maxWidth: "100%", overflowX: "auto" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <thead>
                  <tr style={{ background: "#f2f2f2" }}>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                      Key
                    </th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(tokenURIContent).map((key, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                        {key}
                      </td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                        {JSON.stringify(tokenURIContent[key])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div
                style={{
                  flex: "1",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                <pre style={{ whiteSpace: "pre-wrap", overflowX: "auto" }}>
                  {JSON.stringify(tokenURIContent, null, 2)}
                </pre>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div
                style={{
                  flex: "1",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                <code style={{ whiteSpace: "pre-wrap", overflowX: "auto" }}>
                  {JSON.stringify(tokenURIContent, null, 2)}
                </code>
              </div>
            </div>
          </div>
        )}

        <button
          onClick={handleMint}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            margin: "10px 0",
          }}
        >
          Mint Digital Artifact
        </button>

        <div style={{ margin: "20px 0" }}></div>

        {/* <input
        type="text"
        placeholder="Query address for balance"
        value={queryAddress}
        onChange={(e) => setQueryAddress(e.target.value)}
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          fontSize: '14px',
          boxSizing: 'border-box'
        }}
      /> */}

        {lastMintedUri && <NFTDetails uri={lastMintedUri} />}

        {/* <button
        onClick={handleGetBalance}
        style={{
          padding: '10px 20px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          margin: '10px 0'
        }}
      >
        Get Balance
      </button>
      {balance !== null && (
        <p style={{ margin: '10px 0' }}>
          Balance of {queryAddress}: {balance}
        </p>
      )} */}

        <div style={{ margin: "20px 0" }}></div>

        <a
          href={contractExplorerLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007BFF", textDecoration: "none" }}
        >
          View Contract on Explorer
        </a>
      </div>
    </div>
  );
};

export default MetaMaskConnection;
