// Function to get the balance of an account
export const getBalanceOf = async (contract, account) => {
  try {
    const balance = await contract.methods.balanceOf(account).call();
    return balance;
  } catch (error) {
    console.error("Failed to get balance:", error);
    throw error;
  }
};

// Function to get the total supply of tokens
export const getTotalSupply = async (contract) => {
  try {
    const totalSupply = await contract.methods.totalSupply().call();
    return totalSupply;
  } catch (error) {
    console.error("Failed to get total supply:", error);
    throw error;
  }
};

// Function to mint a new NFT
export const mintNFT = async (contract, fromAddress, toAddress, uri, value) => {
  return contract.methods.safeMint(toAddress, uri).send({ from: fromAddress, value });
};
