import React from 'react';
import './App.css'; // Importing a CSS file for styles
import MetaMaskConnection from './components/MetaMaskConnection';

function App() {
  return (
    <div className="container">
      <header className="header">
        <h1>Evidencer Digital Artifacts</h1>
      </header>
      <main>
        <MetaMaskConnection />
      </main>
      {/* <p>Contract Review and Improvements:

Constructor Invocation:
    The Ownable(msg.sender) in the constructor is correct because it sets the initial owner to the deployer.

Minting Function (mint):
    The mint function correctly mints a new NFT and sets its token URI, ensuring the payment meets the MINT_COST.

Withdraw Function:
    The withdraw function checks if the contract balance is greater than zero and transfers the entire balance to the contract owner.

Overrides:
    The contract includes overrides for functions from ERC721URIStorage and ERC721Enumerable. These are necessary because the contract inherits from both these contracts, and overriding ensures that the contract implements the required behavior correctly.</p> */}
      <footer className="footer">
        <p>&copy; 2024 Web3 Legl Engineering. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;