import React, { useEffect, useState } from 'react';

const NFTDetails = ({ uri }) => {
  const [nftData, setNftData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNftData = async () => {
      try {
        const response = await fetch(uri);
        if (!response.ok) {
          throw new Error('Failed to fetch NFT metadata');
        }
        const data = await response.json();
        setNftData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    if (uri) {
      fetchNftData();
    }
  }, [uri]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!nftData) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h3>NFT Metadata</h3>
      <table>
        <tbody>
          {Object.entries(nftData).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NFTDetails;
